<template>
  <el-dialog
    title="变更候补志愿"
    :visible.sync="dialogVisible"
    width="520px"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <div class="info_1">确认将候补志愿从“<span>{{ standbyChangeDatas.apply_province_name }}-{{ standbyChangeDatas.apply_city_name }}</span>”变更为“<span>
      {{standbyChangeDatas.province_name}}-{{ standbyChangeDatas.city_name }}</span>”吗？</div>

    <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="cancelBtn()">取 消</el-button>
      <el-button class="confirm" @click="confirm()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {seat} from "r/index/signUp";

export default {
  name: "StandbyChange",
  props: {
    standbyChangeData:Object,
    standbyChangeDialogFu: Boolean,
  },
  data() {
    return {
      dialogVisible: this.standbyChangeDialogFu,
      standbyChangeDatas:this.standbyChangeData,
    };
  },
  methods: {
   async confirm() {
     this.$emit("confirm", '');

    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeStandbyChangeDialog", this.dialogVisible);
    },
  },
  watch: {
    standbyChangeDialogFu(val) {
      this.dialogVisible = val;
    },
    standbyChangeData(val){
      this.standbyChangeDatas = val;
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
</style>
