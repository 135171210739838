<template>
  <el-dialog
    title="预定考位"
    :visible.sync="dialogVisible"
    width="520px"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <div class="info_2" v-if="examType == 7">考点省份一旦确认，不可修改。是否确认？</div>
    <div class="info_2" v-else>考点城市一旦确认，不可修改。是否确认？</div>

    <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="cancelBtn()">取 消</el-button>
      <el-button class="confirm" @click="confirm()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {seat} from "r/index/signUp";

export default {
  name: "Reserve",
  props: {
    reserveDialogFu: Boolean,
    examTypeFu: [String, Number],
  },
  data() {
    return {
      dialogVisible: false,
      examType: this.examTypeFu,
    };
  },
  methods: {
   async confirm() {
     this.$emit("confirm", '');
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeReserveDialog", this.dialogVisible);
    },
  },
  watch: {
    reserveDialogFu(val) {
      this.dialogVisible = val;
    },
    examTypeFu(val){
      this.examType = val;
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
</style>
