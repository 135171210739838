<template>
  <el-dialog
    title="抢单失败"
    :visible.sync="dialogVisible"
    width="520px"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <div class="info_2">所选城市考位已满，暂时无法预定。如申请候补，可在考点城市考位数有增加后收到微信或短信通知，是否现在申请？</div>

    <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="cancelBtn()">暂不申请</el-button>
      <el-button class="confirm" @click="confirm()">立即申请</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {seat} from "r/index/signUp";

export default {
  name: "ReserveError",
  props: {
    reserveDialogFu: Boolean,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
   async confirm() {
     this.$emit("confirm", '');
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeReserveDialog", this.dialogVisible);
    },
  },
  watch: {
    reserveDialogFu(val) {
      this.dialogVisible = val;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
</style>
