<template>
    <div id="container">
        <el-main>
            <!-- 面包屑导航区域 -->
            <bread-crumb
                    :type="2"
                    :step="examType==7?5:4"
            ></bread-crumb>

            <!-- 主干区域 -->
            <div class="content">
                <!-- 步骤条组件 -->
                <!--<comm-step :active="2" :percentage="100" :type="2"></comm-step>-->
                <comm-step v-if="examType!=7" :active="2" :percentage="100" :type="3"></comm-step>

                <!-- 搜索、下拉框区域 -->
                <div class="body-header">
                    <el-row>
                        <!-- span属性指的是占据多少列 -->
                        <el-col :span="6">
                            <el-select
                                    v-model="queryInfo.province"
                                    placeholder="省份"
                                    :popper-append-to-body="false"
                                    @change="selectProvince($event, '0')"
                                    clearable
                            >
                                <el-option
                                        v-for="item in province_list"
                                        :key="item.province_id"
                                        :label="item.province_name"
                                        :value="item.province_id"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="6" v-if="examType != 7">
                            <el-select
                                    filterable
                                    v-model="queryInfo.city"
                                    placeholder="搜索城市"
                                    :popper-append-to-body="false"
                                    @change="selectCity"
                                    class="search_select"
                                    clearable
                            >
                                <el-option
                                        v-for="item in search_city"
                                        :key="item.city_id"
                                        :label="item.city_name"
                                        :value="item.city_id"
                                >
                                </el-option>
                            </el-select>
                        </el-col v-i>
                        <el-col :span="4">
                            <el-button>搜索</el-button>
                        </el-col>
                    </el-row>
                </div>

                <!-- 表格区域 -->
                <el-table
                        :data="currentCityList"
                        style="width: 100%"
                        stripe
                        height="594"
                >
                    <el-table-column align="center" label="考点省份">
                        <template slot-scope="scope">
                            <span>{{ getProviceName(scope.row.province_id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="examType != 7"
                            align="center"
                            prop="city_name"
                            label="考点城市"
                    ></el-table-column>
                    <el-table-column align="center" label="剩余名额" v-if="isShowNum && examType != 7">
                        <template slot-scope="scope">
              <span style="color: #333333;" v-if="Number(scope.row.num) > 100"
              >充足</span
              >
                            <span
                                    style="color: #C12416;"
                                    v-else-if="Number(scope.row.num) == 0"
                            >已满</span
                            >
                            <span style="color: #EE7602;" v-else
                            >余{{ scope.row.num }}位</span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                            <el-button
                                    v-if="operateStatus(scope.row) == 1 || !isShowNum"
                                    size="mini"
                                    style="background-color: #563279"
                                    @click="reserve(scope.row)"
                            >预定
                            </el-button
                            >
                            <el-button
                                    v-if="operateStatus(scope.row) == 2 && isShowNum"
                                    size="mini"
                                    style="background-color: #8b2d87"
                                    @click="applyStandby(scope.row)"
                            >申请候补
                            </el-button
                            >
                            <el-button
                                    v-if="operateStatus(scope.row) == 3 && isShowNum"
                                    size="mini"
                                    style="background-color: #8b2d87"
                                    @click="cancelStandby(scope.row)"
                            >取消候补
                            </el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 底部提示区域 -->
                <div class="foot-info" v-if="examType == 7">
                </div>
                <div class="foot-info" v-else>
                    注：
                    <div>
                        1、如志愿考点城市考位名额已满，建议申请候补。申请候补后，当该城市有考位增补，系统将通过手机短信告知。
                    </div>
                    <div>
                        2、如志愿考点城市不在考点城市列表中，可<span
                            @click="postVolunteer()"
                    >点击这里</span
                    >反馈新增考点城市志愿。新增考点城市志愿信息用作THUSSAT项目组对后续考试的考点城市设置的参考。
                    </div>
                </div>
                <div class="foot-btn">
                    <el-button
                            plain
                            @click="onBack()"
                    >返回上一步
                    </el-button>
                </div>
            </div>
            <!--   预定考位确认弹框   -->
            <reserve
                    :reserveDialogFu="reserveDialogFu"
                    :examTypeFu="examType"
                    @closeReserveDialog="closeReserveDialog"
                    @confirm="confirmReserve"
            >
            </reserve>
            <!--   预定考位失败：申请候补弹框   -->
            <reserve-error
                    :reserveDialogFu="reserveErrorDialogFu"
                    @closeReserveErrorDialog="closeReserveErrorDialog"
                    @confirm="confirmReserveError"
            >
            </reserve-error>
            <!-- 申请候补弹框-->
            <standby
                    :standbyData="currentCityInfo"
                    :standbyDialogFu="standbyDialogFu"
                    @closeStandbyDialog="closeStandbyDialog"
            ></standby>
            <!--   变更候补城市弹框   -->
            <standby-change
                    :standbyChangeData="currentCityInfo"
                    :standbyChangeDialogFu="standbyChangeDialogFu"
                    @closeStandbyChangeDialog="closeStandbyChangeDialog"
                    @confirm="confirmStandbyChange"
            >
            </standby-change>
            <!--  取消候补 弹框   -->
            <cancel-standby
                    :cancelStandbyDialogFu="cancelStandbyDialogFu"
                    @closeCancelStandbyDialog="closeCancelStandbyDialog"
                    @cancelStandby="cancelStandbySure"
            ></cancel-standby>
            <!--   反馈志愿城市弹框   -->
            <volunteer
                    :volunteerDialogFu="volunteerDialogFu"
                    @closeVolunteerDialog="closeVolunteerDialog"
            ></volunteer>
        </el-main>
    </div>
</template>

<script>
    import breadCrumb from "c/breadcrumb";
    import commStep from "c/commStep";
    import standby from "c/index/signUp/Standby";
    import cancelStandby from "c/index/signUp/CancelStandby";
    import volunteer from "c/index/signUp/Volunteer";
    import {
        getAccount,
        getAlternateCity,
        getApplyAlternateCity,
        getProAndCity,
        seat,
        substitute,
        unSubstitute,
    } from "r/index/signUp";
    import {signUpInitReq} from "common/request";
    import {SERVER_WS_URL} from "config/server";
    import Reserve from "c/index/signUp/Reserve";
    import ReserveError from "c/index/signUp/ReserveError";
    import StandbyChange from "c/index/signUp/StandbyChange";

    export default {
        name: "SignUpLists",
        data() {
            return {
                queryInfo: {
                    province: "",
                    city: "",
                },
                websocket: null, //websocket连接
                lockReconnect: false, //是否真正建立连接
                timeout: 28 * 1000, //30秒一次心跳
                timeoutObj: null, //心跳心跳倒计时
                serverTimeoutObj: null, //心跳倒计时
                timeoutnum: null, //断开 重连倒计时

                province_list: {}, //省份列表
                city_list: {}, //城市列表
                search_city: {}, // 城市列表-搜索
                currentCityList: [], //当前省份下的城市列表
                reserveDialogFu: false, //预定考位弹框标识
                reserveErrorDialogFu: false, //预定考位失败-申请候补弹框标识
                standbyChangeDialogFu: false, //变更申请志愿
                standbyDialogFu: false, // 控制申请候补弹框的显示与隐藏
                cancelStandbyDialogFu: false, // 控制取消候补弹框的显示与隐藏
                volunteerDialogFu: false, // 控制提交考点城市志愿弹框的显示与隐藏
                examUuid: "", //考试uuid
                examType: 7,
                currentProvinceName: "", //当前省份的名称
                currentCityId: "", //当前操作的city_id
                is_apply: false, //是否申请候补城市
                is_wish: false, //是否申请志愿城市
                currentCityInfo: {
                    //当前选择的省市
                    province_id: "",
                    province_name: "",
                    city_id: "",
                    city_name: "",
                    apply_province_id: "",
                    apply_province_name: "",
                    apply_city_id: "",
                    apply_city_name: "",
                },
                token: "", //
                sendData: {},
                isShowNum: false, // 是否显示表格剩余名额
                flag: true
            };
        },
        components: {
            breadCrumb,
            commStep,
            standby,
            volunteer,
            cancelStandby,
            Reserve,
            ReserveError,
            StandbyChange,
        },
        beforeRouteLeave(to, from, next) {
            this.websocket && this.websocket.close();
            next();
        },
        created() {
            if (
                !window.localStorage.getItem("exam_uuid") ||
                window.localStorage.getItem("exam_uuid") == "" ||
                window.localStorage.getItem("exam_uuid") == "null"
            ) {
                return this.$router.replace({name: "examlist"});
            }
            this.examUuid = window.localStorage.getItem("exam_uuid");
            this.token = window.localStorage.getItem("index-token");
            this.signUpInit();
        },
        mounted() {
            //初始化 websocket 链接
            // this.initWebSocket();
        },
        methods: {
            // 返回上一步
            onBack() {
                if (this.examType == 7) {
                    return this.$router.replace({name: 'examlist'});
                }
                this.$router.replace({name: 'invatation', query: {flag: true}})
            },
            async signUpInit() {
                let examuuid = window.localStorage.getItem("exam_uuid");
                let res = await signUpInitReq(examuuid, 2);
                console.log(res)
                if (res) {
                    this.examType = res.exam_type;
                    if (res.status == 3) {
                        // 邀请码用户，不显示数量
                        this.isShowNum = false;
                    } else {
                        this.isShowNum = true;
                    }
                }
                this.getAlternateCitys();
                // const {data: res} = await signUpInit({exam_uuid: examuuid});
                // console.log(res, "报名初始化完成");
                // if (res.code !== 200) {
                //     if (res.code == 4002139) {
                //         await this.$router.replace({
                //             name: "StepOne",
                //             query: {applicationstatus: 0},
                //         });
                //         return this.$message.info(res.message);
                //     } else if (res.code == 4002131) {
                //         await this.$router.replace({name: "OrderList"});
                //         return this.$message.info(res.message);
                //     }
                // }
                // this.examType = res.exam_type;
                // if (res.status == 3) {
                //     // 邀请码用户，不显示数量
                //     this.isShowNum = false;
                // } else {
                //     this.isShowNum = true;
                // }
            },
            //判断操作按钮显示
            operateStatus(row) {
                //1,预定；2，候补；3，取消候补
                if (row.num > 0) {
                    return 1;
                } else {
                    if (this.is_apply) {
                        if (
                            row.city_id == Number(this.is_apply.city_id) &&
                            this.is_apply.status == 1
                        ) {
                            return 3;
                        } else {
                            return 2;
                        }
                    } else {
                        return 2;
                    }
                }
            },
            //确认预定考位
            confirmReserve() {
                let obj = {
                    exam_uuid: this.examUuid,
                    city_id: this.currentCityInfo.city_id,
                };
                seat(obj)
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.reserveDialogFu = false;
                            this.cancelStandbyDialogFu = false;
                            if (res.data.is_pay && res.data.is_pay == 1) {
                                return this.$router.replace({name: "ViewInfo"});
                            }
                            this.$router.replace({name: "ConfirmInfo"});
                        } else if (res.data.code == 43000) {
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            //预定考位失败--申请候补确认
            confirmReserveError() {
                let obj = {
                    exam_uuid: this.examUuid,
                    city_id: this.currentCityInfo.city_id,
                };
                substitute(obj)
                    .then((res) => {
                        if (res.data.code === 200) {
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            //取消候补城市
            cancelStandbySure() {
                let obj = {
                    exam_uuid: this.examUuid,
                    city_id: this.currentCityInfo.city_id,
                };
                unSubstitute(obj)
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.$message.success("取消候补成功！");
                            this.cancelStandbyDialogFu = false;
                            this.getAlternateCitys();
                            this.$forceUpdate(); //强制刷新
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            //变更候补城市
            confirmStandbyChange() {
                let obj = {
                    exam_uuid: this.examUuid,
                    city_id: this.currentCityInfo.city_id,
                };
                substitute(obj)
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.is_apply = {
                                city_id: this.currentCityInfo.city_id,
                                status: 1,
                            };
                            this.standbyChangeDialogFu = false;
                            this.$forceUpdate(); //强制刷新
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            //获取省份和城市
            getProAndCitys() {
                let _this = this;
                if (this.examUuid && this.examUuid !== "") {
                    getProAndCity({exam_uuid: this.examUuid})
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.province_list = res.data.province_list;
                                this.city_list = res.data.city_list;
                                let currentCity = [];
                                Object.keys(res.data.city_list).forEach((key) => {
                                    // let temp = res.data.city_list[key];
                                    if (this.isShowNum) {
                                        // if (temp.all_num != 0) {
                                        //   currentCity.push(res.data.city_list[key]);
                                        // }
                                        currentCity.push(res.data.city_list[key]);
                                    } else {
                                        currentCity.push(res.data.city_list[key]);
                                    }
                                });
                                this.currentCityList = currentCity;

                                //如果连接正常
                                let obj = {
                                    all: true,
                                    exam_uuid: this.examUuid,
                                    token: this.token,
                                };
                                // 邀请码用户，不用连接socket
                                if (!this.isShowNum) {
                                    return;
                                }
                                if (_this.websocket && _this.websocket.readyState == 1) {
                                    this.sendData = obj;
                                    this.flag = false;
                                    this.websocket && this.websocket.close();
                                    _this.initWebSocket();
                                } else {
                                    this.sendData = obj;
                                    this.flag = false;
                                    _this.initWebSocket();
                                }
                            } else {
                                //测试暂用，根据后端修改
                                this.$message.error(res.data.message);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    this.$message.error("获取uuid失败，请检查网络并刷新页面重试");
                    this.$router.replace({name: "examlist"});
                }
            },
            //获取申请候补城市
            getAlternateCitys() {
                if (this.examUuid && this.examUuid !== "") {
                    getAlternateCity({exam_uuid: this.examUuid})
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.is_apply = res.data.is_apply;
                                this.is_wish = res.data.is_wish;
                                this.getProAndCitys();
                            } else {
                                this.$message.error(res.data.message);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    this.$message.error("获取uuid失败，请检查网络并刷新页面重试");
                    this.$router.replace({name: "examlist"});
                }
            },
            //获取申请候补城市列表
            getApplyAlternateCitys() {
                if (this.examUuid && this.examUuid !== "") {
                    getApplyAlternateCity({exam_uuid: this.examUuid})
                        .then((res) => {
                            if (res.data.code === 200) {
                            } else {
                                this.$message.error(res.data.message);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    this.$message.error("获取uuid失败，请检查网络并刷新页面重试");
                }
            },
            // 选择省份
            selectProvince(val, flag) {
                let _this = this;
                let currentCity = [];
                let searchCity = [];
                for (let key in _this.city_list) {
                    if (val === "") {
                        currentCity.push(_this.city_list[key]);
                        searchCity = [];
                    } else if (_this.city_list[key].province_id == val) {
                        if (_this.isShowNum) {
                            // if (_this.city_list[key].all_num != 0) {
                            //   currentCity.push(_this.city_list[key]);
                            // }
                            currentCity.push(_this.city_list[key]);
                            searchCity.push(_this.city_list[key]);
                        } else {
                            currentCity.push(_this.city_list[key]);
                            searchCity.push(_this.city_list[key]);
                        }
                    }
                }
                _this.currentCityList = currentCity;
                _this.search_city = searchCity;
                if (flag === "0") {
                    let obj = {};
                    _this.queryInfo.city = "";
                    if (val && val !== "") {
                        obj = {
                            province_id: val,
                            exam_uuid: _this.examUuid,
                            token: _this.token,
                        };
                    } else {
                        obj = {
                            all: true,
                            exam_uuid: _this.examUuid,
                            token: _this.token,
                        };
                    }

                    // 邀请码用户，不用连接socket
                    if (!_this.isShowNum) {
                        return;
                    }
                    if (_this.websocket && _this.websocket.readyState == 1) {
                        _this.sendData = obj;
                        _this.flag = true;
                        _this.websocket && _this.websocket.close();
                        _this.initWebSocket();
                    } else {
                        _this.sendData = obj;
                        _this.flag = true;
                        _this.initWebSocket();
                    }
                }
            },
            //选择城市
            selectCity(val) {
                let _this = this;
                if (val && val != "") {
                    _this.queryInfo.city = val;
                    let currentCity = [];
                    for (let key in _this.city_list) {
                        if (key == val) {
                            if (_this.isShowNum) {
                                // if (_this.city_list[key].all_num != 0) {
                                //   currentCity.push(_this.city_list[key]);
                                // }
                                currentCity.push(this.city_list[key]);
                            } else {
                                currentCity.push(this.city_list[key]);
                            }
                        }
                    }
                    _this.currentCityList = currentCity;
                } else {
                    _this.queryInfo.city = "";
                    let currentCity = [];
                    for (let key in _this.city_list) {
                        if (_this.city_list[key].province_id == _this.queryInfo.province) {
                            if (_this.isShowNum) {
                                // if (_this.city_list[key].all_num != 0) {
                                //   currentCity.push(_this.city_list[key]);
                                // }
                                currentCity.push(_this.city_list[key]);
                            } else {
                                currentCity.push(_this.city_list[key]);
                            }
                        }
                    }
                    _this.currentCityList = currentCity;
                }
                let obj = {};
                if (val && val !== "") {
                    obj = {
                        city_id: [val],
                        exam_uuid: _this.examUuid,
                        token: _this.token,
                    };
                } else {
                    obj = {
                        exam_uuid: _this.examUuid,
                        province_id: _this.queryInfo.province,
                        token: _this.token,
                    };
                }
                // 邀请码用户，不用连接socket
                if (!_this.isShowNum) {
                    return;
                }
                if (_this.websocket && _this.websocket.readyState == 1) {
                    _this.sendData = obj;
                    _this.flag = true;
                    _this.websocket && _this.websocket.close();
                    _this.initWebSocket();
                } else {
                    _this.sendData = obj;
                    _this.flag = true;
                    _this.initWebSocket();
                }
            },
            // // 省份城市不联动
            // // 选择省份
            // selectProvince(val, flag) {
            //     console.log(val, flag)
            //     let _this = this;
            //     let currentCity = [];
            //     for (let key in this.city_list) {
            //         if (val === "") {
            //             currentCity.push(this.city_list[key]);
            //         } else if (this.city_list[key].province_id == val) {
            //             if (this.isShowNum) {
            //                 // if (this.city_list[key].all_num != 0) {
            //                 //   currentCity.push(this.city_list[key]);
            //                 // }
            //                 currentCity.push(this.city_list[key]);
            //             } else {
            //                 currentCity.push(this.city_list[key]);
            //             }
            //         }
            //     }
            //     this.currentCityList = currentCity;
            //     this.search_city = currentCity;
            //     if (flag === "0") {
            //         let obj = {};
            //         this.queryInfo.city = "";
            //         if (val && val !== "") {
            //             obj = {
            //                 exam_uuid: this.examUuid,
            //                 province_id: val,
            //                 token: this.token,
            //             };
            //         } else {
            //             obj = {
            //                 all: true,
            //                 exam_uuid: this.examUuid,
            //                 token: this.token,
            //             };
            //         }
            //
            //         // 邀请码用户，不用连接socket
            //         if (!this.isShowNum) {
            //             return;
            //         }
            //         if (_this.websocket && _this.websocket.readyState == 1) {
            //             this.sendData = obj;
            //             this.flag = true;
            //             this.websocket && this.websocket.close();
            //             _this.initWebSocket();
            //         } else {
            //             this.sendData = obj;
            //             this.flag = true;
            //             _this.initWebSocket();
            //         }
            //     }
            // },
            // //选择城市
            // selectCity(val) {
            //     console.log(val)
            //     let _this = this;
            //     if (val && val != "") {
            //         this.queryInfo.city = val;
            //         this.queryInfo.province = this.city_list[val].province_id;
            //         let currentCity = [];
            //         for (let key in this.city_list) {
            //             if (key == val) {
            //                 if (this.isShowNum) {
            //                     // if (this.city_list[key].all_num != 0) {
            //                     //   currentCity.push(this.city_list[key]);
            //                     // }
            //                     currentCity.push(this.city_list[key]);
            //                 } else {
            //                     currentCity.push(this.city_list[key]);
            //                 }
            //             }
            //         }
            //         this.currentCityList = currentCity;
            //         // this.selectProvince(this.city_list[val].province_id, "1");
            //
            //     } else {
            //         this.queryInfo.city = "";
            //         this.queryInfo.province = "";
            //         let currentCity = [];
            //         for (let key in this.city_list) {
            //             if (this.isShowNum) {
            //                 // if (this.city_list[key].all_num != 0) {
            //                 //   currentCity.push(this.city_list[key]);
            //                 // }
            //                 currentCity.push(this.city_list[key]);
            //             } else {
            //                 currentCity.push(this.city_list[key]);
            //             }
            //         }
            //         this.currentCityList = currentCity;
            //     }
            //     let obj = {};
            //     if (val && val !== "") {
            //         obj = {
            //             city_id: [val],
            //             exam_uuid: this.examUuid,
            //             token: this.token,
            //         };
            //     } else {
            //         obj = {
            //             all: true,
            //             exam_uuid: this.examUuid,
            //             token: this.token,
            //         };
            //     }
            //     // 邀请码用户，不用连接socket
            //     if (!this.isShowNum) {
            //         return;
            //     }
            //     if (_this.websocket && _this.websocket.readyState == 1) {
            //         this.sendData = obj;
            //         this.flag = true;
            //         this.websocket && this.websocket.close();
            //         _this.initWebSocket();
            //     } else {
            //         this.sendData = obj;
            //         this.flag = true;
            //         _this.initWebSocket();
            //     }
            // },
            //根据id获取省份名称
            getProviceName(id) {
                return this.province_list[id].province_name;
            },
            //点击预定按钮
            reserve(row) {
                this.currentCityInfo.province_id = row.province_id;
                this.currentCityInfo.province_name = this.getProviceName(row.province_id);
                this.currentCityInfo.city_id = row.city_id;
                this.currentCityInfo.city_name = row.city_name;
                this.reserveDialogFu = true;
            },
            //取消预定考位弹框
            closeReserveDialog(val) {
                this.reserveDialogFu = val;
            },
            //取消预定考位失败-申请候补弹框
            closeReserveErrorDialog(val) {
                this.reserveErrorDialogFu = val;
            },
            //取消 变更志愿弹框
            closeStandbyChangeDialog(val) {
                this.standbyChangeDialogFu = val;
            },
            // 子组件触发，关闭提交考点城市志愿弹框
            closeVolunteerDialog(val) {
                this.volunteerDialogFu = val;
            },
            // 子组件触发，关闭取消候补弹框
            closeCancelStandbyDialog(val) {
                this.cancelStandbyDialogFu = val;
            },
            // 子组件触发，关闭申请候补弹框
            closeStandbyDialog(val) {
                this.standbyDialogFu = val;
            },
            // 新增考点城市志愿
            postVolunteer() {
                this.volunteerDialogFu = true;
            },
            // 取消候补
            cancelStandby(row) {
                this.currentCityInfo.province_id = row.province_id;
                this.currentCityInfo.province_name = this.getProviceName(row.province_id);
                this.currentCityInfo.city_id = row.city_id;
                this.currentCityInfo.city_name = row.city_name;
                this.cancelStandbyDialogFu = true;
            },
            // 申请候补
            applyStandby(row) {
                this.currentCityInfo.province_id = row.province_id;
                this.currentCityInfo.province_name = this.getProviceName(row.province_id);
                this.currentCityInfo.city_id = row.city_id;
                this.currentCityInfo.city_name = row.city_name;
                this.isApplyStandby();
            },
            //判断是否已申请候补
            isApplyStandby() {
                getAlternateCity({exam_uuid: this.examUuid})
                    .then((res) => {
                        console.log(res, "res");
                        if (res.data.code === 200) {
                            this.is_apply = res.data.is_apply;
                            // this.currentCityInfo.apply_province_id = res.data.is_apply
                            this.is_wish = res.data.is_wish;
                            console.log(res.data.is_apply, "res111");
                            console.log(res.data.is_apply.status, "res222");
                            console.log(res.data.is_apply.city_id, "res333");
                            console.log(this.currentCityInfo.city_id, "res444");
                            if (
                                res.data.is_apply &&
                                res.data.is_apply.status == 1 &&
                                res.data.is_apply.city_id !== this.currentCityInfo.city_id
                            ) {
                                this.currentCityInfo.apply_province_id =
                                    res.data.is_apply.province_id;
                                this.currentCityInfo.apply_province_name = this.getProviceName(
                                    res.data.is_apply.province_id
                                );
                                this.currentCityInfo.apply_city_id = res.data.is_apply.city_id;
                                this.currentCityInfo.apply_city_name = this.city_list[
                                    res.data.is_apply.city_id
                                    ].city_name;
                                this.standbyChangeDialogFu = true;
                            } else {
                                let obj = {
                                    exam_uuid: this.examUuid,
                                    city_id: this.currentCityInfo.city_id,
                                };
                                substitute(obj)
                                    .then((res) => {
                                        console.log(res, "res");
                                        if (res.data.code === 200) {
                                            this.is_apply = {
                                                city_id: this.currentCityInfo.city_id,
                                                status: 1,
                                            };
                                            this.$forceUpdate(); //强制刷新
                                            this.standbyDialogFu = true;
                                        } else {
                                            this.$message.error(res.data.message);
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            }
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            // 初始化 webSocket
            initWebSocket() {
                if (typeof WebSocket == "undefined") {
                    this.$message({
                        showClose: true,
                        message: "您的浏览器不支持WebSocket",
                        type: "error",
                    });
                } else {
                    if (!this.token || this.token == "") {
                        this.token = window.localStorage.getItem("index-token");
                    }
                    const wssURL =
                        SERVER_WS_URL + "/city?token=" + this.token + "&channel=pc";
                    this.websocket = new WebSocket(wssURL);
                    // 连接发生错误的回调方法
                    this.websocket.onerror = this.websocketOnerror;
                    // 连接成功建立的回调方法
                    this.websocket.onopen = this.websocketOnopen;
                    // 接收到消息的回调方法
                    this.websocket.onmessage = this.websocketOnmessage;
                    // 连接关闭的回调方法
                    this.websocket.onclose = this.websocketOnclose;
                    // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
                    this.websocket.onbeforeunload = this.websocketOnbeforeunload;
                }
            },
            reconnect() {
                //重新连接
                this.initWebSocket();

                // let that = this;
                // if (that.lockReconnect) {
                //   return;
                // }
                // that.lockReconnect = true;
                // //没连接上会一直重连，设置延迟避免请求过多
                // that.timeoutnum && clearTimeout(that.timeoutnum);
                // that.timeoutnum = setTimeout(function () {
                //   //新连接
                //   that.initWebSocket();
                //   that.lockReconnect = false;
                // }, 5000);
            },
            reset() {
                //重置心跳
                let that = this;
                //清除时间
                clearTimeout(that.timeoutObj);
                clearTimeout(that.serverTimeoutObj);
                //重启心跳
                that.start();
            },
            start() {
                //开启心跳
                let self = this;
                self.timeoutObj && clearTimeout(self.timeoutObj);
                self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
                self.timeoutObj = setTimeout(function () {
                    //这里发送一个心跳，后端收到后，返回一个心跳消息，
                    if (self.websocket.readyState == 1) {
                        //如果连接正常
                        self.websocket.send("heartCheck");
                    } else {
                        //否则重连
                        self.reconnect();
                    }
                    self.serverTimeoutObj = setTimeout(function () {
                        //超时关闭
                        self.websocket.close();
                    }, self.timeout);
                }, self.timeout);
            },
            // 连接发生错误的回调方法
            websocketOnerror() {
                this.$message.error("WebSocket连接发生错误，请检查网络或刷新页面重试！");
                //重连
                this.reconnect();
            },
            // 连接成功建立的回调方法
            websocketOnopen() {
                console.log("连接成功建立的回调方法");
                //数据发送
                if (this.websocket.readyState == 1) {
                    if (Object.keys(this.sendData).length > 0) {
                        this.websocket.send(JSON.stringify(this.sendData));
                    }
                }
            },
            websocketSend() {
                //数据发送
                if (Object.keys(this.sendData).length > 0) {
                    this.websocket.send(JSON.stringify(this.sendData));
                }
            },
            // 接收到消息的回调方法
            websocketOnmessage(event) {
                if (event.data != "连接成功") {
                    console.log(this.flag)
                    if (this.flag) {
                        const data = JSON.parse(event.data);
                        if (Object.keys(data).length != this.currentCityList.length) {
                            this.getProAndCitys();
                        }
                        this.currentCityList.forEach((item) => {
                            // console.log(item,'item===')
                            if (
                                data[item.city_id] &&
                                item.city_id == data[item.city_id].city_id
                            ) {
                                item.num = data[item.city_id].num;
                                this.city_list[item.city_id].num = data[item.city_id].num;
                            }
                        });
                    } else {
                        const data = JSON.parse(event.data).city_list;
                        if (Object.keys(data).length != this.currentCityList.length) {
                            this.getProAndCitys();
                        }
                        this.currentCityList.forEach((item) => {
                            if (
                                data[item.city_id] &&
                                item.city_id == data[item.city_id].city_id
                            ) {
                                item.num = data[item.city_id].num;
                                this.city_list[item.city_id].num = data[item.city_id].num;
                            }
                        });
                    }
                }
            },
            // 连接关闭的回调方法
            websocketOnclose() {
                //重连
                // this.reconnect();
                console.log("连接关闭的回调方法");
            },
            // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常
            websocketOnbeforeunload() {
                this.closeWebSocket();
                console.log(
                    "监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常"
                );
            },
            // 关闭WebSocket连接
            closeWebSocket() {
                this.websocket && this.websocket.close();
            },

            // 返回上一步
        },

        // 页面注销时候调用 避免连接错误
        destroyed() {
            this.closeWebSocket();
        },
    };
</script>

<style scoped lang="scss">
    // 面包屑样式
    @import "a/scss/beradcrumb";
    // 表格样式
    @import "a/scss/table";

    #container {
        width: 100%;
        min-height: 80%;
        margin: 0 auto;
        overflow: auto;

        .el-main {
            max-width: 1300px;
            margin: 6px auto;
            padding: 0 50px;
            height: 1140px;

            .content {
                background-color: #ffffff;
                padding: 60px;
                height: 1080px;
            }
        }
    }

    // 搜索、下拉框区域
    ::v-deep .body-header {
        margin-top: 40px;

        .el-col-6 {
            &:nth-child(2) {
                margin-left: 10px;
            }
        }

        // 下拉框
        .el-select {
            width: 100%;
            height: 50px;
            line-height: 50px;

            .el-input__inner {
                height: 50px;
                line-height: 50px;
                border-radius: 4px;
                border: 1px solid #e1e3e6;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #999999;
            }

            .el-input__icon {
                line-height: 50px;

                &::before {
                    font-size: 18px !important;
                }
            }

            &.search_select {
                .el-input__inner {
                    border-radius: 4px 0 0 4px;
                }
            }
        }

        .el-scrollbar {
            .el-select-dropdown__item {
                height: 50px;
                line-height: 50px;
                border-radius: 4px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #999999;
            }
        }

        // 按钮
        .el-button {
            width: 80px;
            height: 50px;
            color: #ffffff;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            background-color: rgba(86, 50, 121, 0.8);
            border-radius: 0px 4px 4px 0px;
            border-color: rgba(86, 50, 121, 0.8);
        }
    }

    // 下拉框下拉选项区域
    .el-select-dropdown__item {
        height: 40px;
        line-height: 40px;

        &.selected {
            color: #563279 !important;
        }
    }

    ::v-deep .el-table {
        margin-top: 20px;
    }

    // 底部提示区域
    .foot-info {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        margin: 27px 0 70px 0;

        span {
            color: #d7770e;
            cursor: pointer;
            font-weight: bold;
            display: inline-block;
        }
    }

    .foot-btn {
        text-align: center;

        .el-button {
            width: 140px;
            height: 48px;
            border-radius: 4px;
            color: #563279;
            border-color: #563279;
        }
    }
</style>
