<template>
  <el-dialog
    title="新增考点城市志愿"
    :visible.sync="dialogVisible"
    width="520px"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
    @open="getApplyAlternateCity()"
  >
    <div class="info_1">每个账户限填一次</div>
    <el-form :model="dialogForm" ref="dialogFormRef">
      <el-form-item label="省份">
        <el-select
          v-model="dialogForm.province_id"
          placeholder="省份"
          @change="onChange"
        >
          <el-option
            v-for="(item, index) in provinceList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="城市">
        <el-select v-model="dialogForm.city_id" placeholder="城市">
          <el-option
            v-for="(item, index) in cityList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <div class="info_2">
      注：新增考点城市志愿信息用作THUSSAT项目组对后续考试的考点城市设置的参考。
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="cancelBtn()">取 消</el-button>
      <el-button class="confirm" @click="confirm()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getApplyAlternateCity, subAltCity } from "r/index/signUp.js";
export default {
  name: "Volunteer",
  props: {
    volunteerDialogFu: Boolean,
  },
  data() {
    return {
      examuuid: "",
      dialogVisible: this.volunteerDialogFu,
      dialogForm: {
        province_id: "",
        city_id: "",
        exam_uuid: "",
      },
      province_list: {},
      city_list: {},
      provinceList: [],
      cityList: [],
    };
  },
  methods: {
    async getApplyAlternateCity() {
      this.dialogForm.exam_uuid = window.localStorage.getItem("exam_uuid");
      const { data: res } = await getApplyAlternateCity({
        exam_uuid: this.dialogForm.exam_uuid,
      });
      console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.province_list = res.province_list;
      this.city_list = res.city_list;
      let provinceList = [];
      for (let k in this.province_list) {
        this.province_list[k]['children'] = [];
        for (let i in this.city_list) {
          if (this.city_list[i]["parent_id"] == this.province_list[k]['id']) {
            this.province_list[k]['children'].push(this.city_list[i]);
          }
        }
        if (this.province_list[k]['children'].length > 0) {
          provinceList.push(this.province_list[k]);
        }
      }
      this.provinceList = provinceList;
      console.log(this.provinceList)
    },
    async onChange(val) {
      let cityList = [];
      this.dialogForm.city_id = ''
      this.provinceList.forEach(item=>{
        if(item.id == val){
          cityList = item['children']
        }
      })
      this.cityList = cityList;
    },
    async confirm() {
      if(this.dialogForm.city_id && this.dialogForm.city_id !='' && this.dialogForm.province_id && this.dialogForm.province_id !=''){
        const { data: res } = await subAltCity(this.dialogForm);
        console.log(res);
        if (res.code != 200) return this.$message.error(res.message);
        this.cancelBtn();
        this.$message.success(res.message);
      }else{
        this.$message.error('请选择省份/城市!')
      }

    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogForm.province_id = "";
      this.dialogForm.city_id = "";
      this.dialogVisible = false;
      this.$emit("closeVolunteerDialog", this.dialogVisible);
    },
  },
  watch: {
    volunteerDialogFu() {
      this.dialogVisible = this.volunteerDialogFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
.info_1{
  margin:0 0 10px 0 !important;
  color: #d7770e !important;
}
</style>
