<template>
  <el-dialog
    title="申请候补说明"
    :visible.sync="dialogVisible"
    width="520px"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <div class="info_1">
      您已成功提交“<span>{{standbyDatas.province_name}}-{{ standbyDatas.city_name }}</span>”的考位候补申请。
    </div>
    <div class="info_2">
      注：提交候补申请后，一旦有考点增补考位，将会收到短信通知。登录THUSSAT官网，在“考试列表—立即报名—选择考点城市”完成预定。
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button class="confirm" @click="confirm()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "Standby",
  props: {
    standbyData:Object,
    standbyDialogFu: Boolean,
  },
  data() {
    return {
      dialogVisible: this.standbyDialogFu,
      standbyDatas:this.standbyData,
    };
  },
  methods: {
    confirm() {
      this.cancelBtn();
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeStandbyDialog", this.dialogVisible);
    },
  },
  watch: {
    standbyDialogFu() {
      this.dialogVisible = this.standbyDialogFu;
    },
    standbyData(val){
      this.standbyDatas = val;
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
</style>
