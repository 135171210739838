<template>
  <el-dialog
    title="取消候补说明"
    :visible.sync="dialogVisible"
    width="520px"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <div class="info_2">
      确认取消候补申请吗？取消后，将不再接收短信通知。
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="cancelBtn()">取 消</el-button>
      <el-button class="confirm" @click="confirm()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "CancelStandby",
  props: {
    cancelStandbyDialogFu: Boolean,
  },
  data() {
    return {
      dialogVisible: this.cancelStandbyDialogFu,
    };
  },
  methods: {
    confirm() {
      this.$emit("cancelStandby", '');
        // this.cancelBtn()
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeCancelStandbyDialog", this.dialogVisible);
    },
  },
  watch: {
    cancelStandbyDialogFu() {
      this.dialogVisible = this.cancelStandbyDialogFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
</style>
